import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "./assets/imgs/healthar-logo.svg";
import HeroBG from "./assets/imgs/healthcare-bg.png";
import DrKeven from "./assets/imgs/DrKeven.png";
import Testimonial1 from "./assets/imgs/Testimonial1.png";
import Testimonial2 from "./assets/imgs/Testimonial2.png";
import HexagonBG from "./assets/imgs/hexagon-bg.png";
import Feature1 from "./assets/imgs/Feature1.png";
import {
  BsFacebook,
  BsFillStarFill,
  BsInstagram,
  BsList,
  BsTwitterX,
  BsXLg,
} from "react-icons/bs";
import "./App.css";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

const App = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    closeMobileMenu(); // Close the mobile menu after scrolling
  };
  return (
    <div className="healthar">
      <main className="healthar-main">
        <header>
          <div className="box">
            <div className="header-content">
              <img src={Logo} alt="" />
              <div className="nav-menu">
                <a href="#home" onClick={() => handleScroll("home")}>
                  Home
                </a>
                <a href="#about" onClick={() => handleScroll("about")}>
                  About
                </a>
                <a href="#features" onClick={() => handleScroll("features")}>
                  Features
                </a>
                <a
                  href="#testimonials"
                  onClick={() => handleScroll("testimonials")}
                >
                  Testimonials
                </a>
                <a href="#contact" onClick={() => handleScroll("contact")}>
                  Contact
                </a>
              </div>
              <div className="header-left">
                <button
                  className="main-btn"
                  onClick={() => handleScroll("contact")}
                >
                  Get Started
                </button>
                <BsList className="mob-hamburger" onClick={toggleMobileMenu} />
              </div>
              {isMobileMenuOpen && (
                <div className="mobile-menu">
                  <div className="mobile-menu-top">
                    <a href="/">
                      <img src={Logo} alt="" />
                    </a>
                    <BsXLg onClick={closeMobileMenu} />
                  </div>
                  <div className="mobile-menu-bottom">
                    <a href="#home" onClick={() => handleScroll("home")}>
                      Home
                    </a>
                    <a href="#about" onClick={() => handleScroll("about")}>
                      About
                    </a>
                    <a
                      href="#features"
                      onClick={() => handleScroll("features")}
                    >
                      Features
                    </a>
                    <a
                      href="#testimonials"
                      onClick={() => handleScroll("testimonials")}
                    >
                      Testimonials
                    </a>
                    <a href="#contact" onClick={() => handleScroll("contact")}>
                      Contact
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>
        <section id="home" className="hero-section">
          <img src={HeroBG} alt="" />
          <div className="linear-gradient"></div>
          <div className="box">
            <div className="hero-content">
              <h1>
                Empower Your <br />
                <span> Health Journey</span> with <br /> Augmented Reality
              </h1>
              <p>
                HealthAR puts powerful, interactive health insights right in
                your hands.
              </p>
              <button
                className="main-btn"
                onClick={() => handleScroll("contact")}
              >
                Learn More
              </button>
            </div>
          </div>
        </section>
        <section id="about" className="about-section">
          <div className="box">
            <div className="about-content">
              <div className="content-top">
                <h6>About HealthAR</h6>
                <h2>
                  Our Mission: <span> Health</span> Knowledge <br /> for All
                </h2>
                <p>
                  At HealthAR, we believe everyone deserves access to clear,
                  interactive health insights. Our mission is to make health
                  information accessible and engaging, empowering people to take
                  control of their wellness through AR and AI-driven tools.
                </p>
              </div>
              <div className="about-content-grid">
                <div className="acg-left">
                  <h3>Founder’s Story:</h3>
                  <p>
                    “Growing up on the Southside of <b>Chicago</b>, I saw
                    firsthand the health challenges faced by underserved
                    communities. As a Family Medicine physician, I’ve treated
                    hundreds of patients who felt overwhelmed by medical
                    information, unsure of how to manage their own health. I
                    knew there had to be a better way, so I created{" "}
                    <b>HealthAR</b>.” <br />
                    <br /> <b>— Dr. Keven Stonewall Founded</b> <br />
                    by Dr. Keven Stonewall, a Family Medicine physician and
                    advocate for health equity, HealthAR combines medical
                    expertise with intuitive technology to empower people from
                    all backgrounds. HealthAR was built to make health knowledge
                    simple, accessible, and easy to act on—so everyone can make
                    informed choices for themselves and their families.
                  </p>
                  <h5>Why HealthAR?</h5>
                  <p>
                    HealthAR was created to bridge the health knowledge gap,
                    especially for those who’ve often been left out. Designed to
                    be simple and approachable, HealthAR lets you learn about
                    your health in a way that’s easy to understand, with tools
                    that help you navigate health challenges confidently.
                  </p>
                  <h5>Our Vision</h5>
                  <p>
                    HealthAR isn’t just an app—it’s a breakthrough in precision
                    self-management for personal health. By leveraging
                    cutting-edge AR and AI technology, we’re empowering
                    everyone, regardless of background or health literacy, to
                    visualize, understand, and take control of their wellness in
                    a way that’s both accessible and tailored to their unique
                    needs. HealthAR simplifies complex health information into
                    precise, actionable insights, helping users make smart,
                    informed decisions. Our mission is to set a new standard for
                    healthcare accessibility and build a healthier, more
                    equitable future, one empowered individual at a time.
                  </p>
                </div>
                <div className="acg-right">
                  <img src={DrKeven} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="about-section">
          <div className="linear-gradient-revert"></div>
          <img src={HexagonBG} alt="" className="hexagon-img" />
          <div className="box">
            <div className="about-content">
              <div className="content-top">
                <h6>Key Features</h6>
                <h2>
                  Explore <span> HealthAR’s</span> Game-Changing <br /> Features
                </h2>
              </div>
              <div className="features-swiper">
                <Swiper
                  pagination={true}
                  modules={[Autoplay, Pagination]}
                  spaceBetween={20}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="swiper-content">
                      <div className="swiper-left">
                        <h3>Feature 1:</h3>
                        <h2>Interactive 3D Health Models</h2>
                        <i>
                          Explore Your Body in AR: Health at Your Fingertips
                        </i>
                        <p>
                          Dive into 3D models of the human body and explore
                          organs and systems with AR. HealthAR brings your
                          health to life, making it easy to understand complex
                          concepts.
                        </p>
                      </div>
                      <div className="swiper-right">
                        <img src={Feature1} alt="" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-content">
                      <div className="swiper-left">
                        <h3>Feature 2:</h3>
                        <h2>Personalized Health Goals & Tracking</h2>
                        <i>Build Healthy Habits with Personal Tracking</i>
                        <p>
                          Set goals for hydration, sleep, activity, and more.
                          HealthAR’s smart reminders and tracking tools support
                          your journey in building healthy habits.
                        </p>
                      </div>
                      <div className="swiper-right">
                        <img src={Feature1} alt="" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-content">
                      <div className="swiper-left">
                        <h3>Feature 3:</h3>
                        <h2>Medication & Symptom Management</h2>
                        <i>Organize Your Health Routine Effortlessly</i>
                        <p>
                          Log medications, monitor symptoms, and never miss a
                          dose. HealthAR simplifies health management so you can
                          focus on feeling your best.
                        </p>
                      </div>
                      <div className="swiper-right">
                        <img src={Feature1} alt="" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-content">
                      <div className="swiper-left">
                        <h3>Feature 4:</h3>
                        <h2>AI Health Coach</h2>
                        <i>Your Personalized AI Health Companion</i>
                        <p>
                          Receive real-time insights and reminders tailored to
                          your health goals. HealthAR’s AI coach guides you in
                          making daily wellness decisions.
                        </p>
                      </div>
                      <div className="swiper-right">
                        <img src={Feature1} alt="" />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" className="about-section">
          <div className="linear-gradient-revert"></div>
          <img src={HexagonBG} alt="" className="hexagon-img" />
          <div className="box">
            <div className="about-content">
              <div className="content-top">
                <h6>Testimonials</h6>
                <h2>
                  What Our <span> Users</span> Are Saying
                </h2>
              </div>
              <div className="testimonial-content">
                <div className="testimonial-box">
                  <div className="tb-left">
                    <div className="tb-ratings">
                      <BsFillStarFill />
                      <BsFillStarFill />
                      <BsFillStarFill />
                      <BsFillStarFill />
                      <BsFillStarFill />
                    </div>
                    <h5>
                      AIRA has been like a personal health coach in my pocket.
                      Every day, I get advice tailored just for me—on hydration,
                      exercise, and nutrition—all in a way that fits my
                      lifestyle. It’s intuitive, feels personalized, and keeps
                      me motivated. HealthAR isn’t just another health app; it’s
                      a whole new way to stay on top of my wellness.
                    </h5>
                    <h4>User, Age 23</h4>
                  </div>
                  <div className="tb-right">
                    <img src={Testimonial1} alt="" />
                  </div>
                </div>
                <div className="testimonial-box">
                  <div className="tb-left">
                    <div className="tb-ratings">
                      <BsFillStarFill />
                      <BsFillStarFill />
                      <BsFillStarFill />
                      <BsFillStarFill />
                      <BsFillStarFill />
                    </div>
                    <h5>
                      HealthAR’s Med Scanner makes managing my medications so
                      much easier. I just scan my prescription, and I get
                      instant details—uses, side effects, even refill reminders.
                      It’s like having a pharmacist on call anytime I need it.
                      Staying on track with my health has never been this simple
                      or accessible.
                    </h5>
                    <h4>User, Age 41</h4>
                  </div>
                  <div className="tb-right">
                    <img src={Testimonial2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section cta-bg-section">
          <div className="box">
            <div className="call-to-action-content">
              <h2>Ready to Transform Your Health?</h2>
              <p>
                Join a growing community of HealthAR users who are taking
                control of their wellness. Don’t wait to understand your
                health—start today with HealthAR.
              </p>
              <div className="buttons-flex">
                {/* <button className="white-revert-btn">Download Now</button> */}
                <button
                  className="white-btn"
                  onClick={() =>
                    (window.location.href =
                      "mailto:keven.stonewall@gmail.com?subject=Join%20Waitlist&body=Hi,%20I%20would%20like%20to%20join%20the%20waitlist.")
                  }
                >
                  Join the Waitlist
                </button>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="about-section">
          <div className="linear-gradient"></div>
          <img src={HexagonBG} alt="" className="hexagon-img" />
          <div className="box">
            <div className="about-content">
              <div className="content-top">
                <h6>⁠Contact</h6>
                <h2>
                  Connect with <span> HealthAR</span>{" "}
                </h2>
                <p>
                  For business inquiries, partnerships, or questions, reach out
                  through the form. We’d love to connect.
                </p>
                <div className="social-icons">
                  <a href="#">
                    <BsFacebook />
                  </a>
                  <a href="#">
                    <BsInstagram />
                  </a>
                  <a href="#">
                    <BsTwitterX />
                  </a>
                </div>
              </div>
              <div className="contact-form">
                <div className="label-input">
                  <label htmlFor="">Name*</label>
                  <div>
                    <input type="text" placeholder="First Name" />
                    <input type="text" placeholder="Last Name" />
                  </div>
                </div>
                <div className="label-input">
                  <label htmlFor="">Email*</label>
                  <input
                    type="email"
                    name=""
                    id=""
                    placeholder="Email Address"
                  />
                </div>
                <div className="label-input">
                  <label htmlFor="">Subject</label>
                  <input type="email" name="" id="" placeholder="Subject" />
                </div>
                <div className="label-input">
                  <label htmlFor="">Message</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Enter Your Message"
                  ></textarea>
                </div>
                <button className="main-btn">Send</button>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="box">
            <div className="footer-content">
              <div className="footer-content-top">
                <img src={Logo} alt="" />
                <div className="nav-menu">
                  <a href="#">Terms & Conditions</a>
                  <a href="#">Privacy Policy</a>
                </div>
              </div>
              <span>© 2024 Health AR. All rights reserved.</span>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default App;
